<template>
  <v-sheet class="secondary">
    <v-container>
      <v-row class="white--text">
        <v-col
          align="center"
          cols="12"
          md="5"
          lg="5"
        >
          <v-img
            src="@/assets/logo-white.png"
            :max-width="getWidth()"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <h5 class="overline font-weight-bold text-xl-h6">Atendimento</h5>
          <ul class="pa-0 caption font-weight-light text-xl-body-1">
            <li
              v-for="(contact, idx) in items"
              :key="idx"
              class="pb-1"
            >
              <v-icon
                dark
                :small="!$vuetify.breakpoint.xl"
                v-text="contact.icon"
                class="pr-3"
              />
              {{ contact.text }}
            </li>
          </ul>
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="3"
        >
          <h5 class="overline font-weight-bold text-xl-h6">Redes Sociais</h5>
          <SocialButtons
            color="white"
            bg="primary"
          />
          <!-- <h5 class="overline font-weight-bold text-xl-h6">Gravações</h5>
          <v-row dense>
            <v-col cols="4">
              <ul class="pl-0">
                <li
                  class="caption font-weight-light text-xl-body-1"
                  v-for="(print, idx) in prints"
                  :key="idx"
                >
                  <a
                    v-if="idx < 5"
                    class="white--text"
                    @click="goTo(print)"
                    v-text="print.title"
                  />
                </li>
              </ul>
            </v-col>
            <v-col
              cols="6"
              v-if="prints != null"
            >
              <ul>
                <li
                  class="caption font-weight-light text-xl-body-1"
                  v-for="(print, idx) in prints"
                  :key="idx"
                >
                  <a
                    v-if="idx > 4"
                    class="white--text"
                    @click="goTo(print)"
                    v-text="print.title"
                  />
                </li>
              </ul>
            </v-col>
          </v-row> -->
        </v-col>
        <!-- <v-col
          cols="12"
          md="2"
        >
          <h5 class="overline font-weight-bold text-xl-h6">Categorias</h5>
          <v-row dense>
            <v-col cols="4">
              <ul class="pl-0">
                <li
                  class="caption font-weight-light text-xl-body-1"
                  v-for="(main, idx) in mainCategories"
                  :key="idx"
                >
                  <a
                    v-if="idx < 5"
                    class="white--text"
                    @click="setProductsCategorySearch(1, main)"
                    v-text="main"
                  />
                </li>
              </ul>
              <ul class="pl-0">
                <li
                  class="caption font-weight-light text-xl-body-1"
                  v-for="(special, idx) in specialDates"
                  :key="idx"
                >
                  <a
                    v-if="idx < 5"
                    class="white--text"
                    @click="setProductsCategorySearch(2, special)"
                    v-text="special"
                  />
                </li>
              </ul>
              <ul class="pl-0">
                <li
                  class="caption font-weight-light text-xl-body-1"
                  v-for="(segment, idx) in segments"
                  :key="idx"
                >
                  <a
                    v-if="idx < 5"
                    class="white--text"
                    @click="setProductsCategorySearch(3, segment)"
                    v-text="segment"
                  />
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-col> -->
        <!-- <v-col cols="12" md="2">
                    <h5 class="overline font-weight-bold text-xl-h6">Ajuda</h5>
                    <ul class="pl-0">
                        <li
                            class="caption font-weight-light text-xl-body-1"
                            v-for="(item, idx) in faq"
                            :key="idx"
                        >
                            <a
                                v-if="idx < 6"
                                class="white--text"
                                v-text="item.title"
                            />
                        </li>
                    </ul>
                </v-col> -->
      </v-row>
    </v-container>
    <v-divider class="primary" />
    <p class="body-2 white--text text-center py-2 mb-0">
      Magazine Brindes &copy; 2022 - Todos os direitos reservados
    </p>
  </v-sheet>
</template>
<script>
export default {
  components: {
    SocialButtons: () => import('./widgets/SocialButtons'),
  },
  data: () => ({
    items: [
      { text: '(11) 9.8578-0621', icon: 'fab fa-whatsapp' },
      { text: '(11) 9.8578-0621', icon: 'fas fa-phone-alt' },
      { text: 'vendas@magazinebrindes.com.br', icon: 'fas fa-envelope' },
      { text: '', icon: 'fas fa-map-marker-alt' },
    ],
    prints: null,
    // [
    // { title: 'Cromia', route: 'cromia' },
    // { title: 'Digital UV', route: 'digital-uv' },
    // { title: 'Etiqueta Resinada', route: 'etiqueta-resinada' },
    // { title: 'Hot Stamping', route: 'hot-stamping' },
    // { title: 'Laser', route: 'laser' },
    // { title: 'Off-set', route: 'off-set' },
    // { title: 'Silk Screen', route: 'silk-screen' },
    // { title: 'Sleeve', route: 'sleeve' },
    // { title: 'Sublimação', route: 'sublimacao' },
    // { title: 'Tampografia', route: 'tampografia' },
    // { title: 'Transfer', route: 'transfer' },
    // { title: 'Relevo', route: 'relevo' },
    // ]
    faq: [
      { title: 'Compra Segura' },
      { title: 'Como comprar' },
      { title: 'Dúvidas Frequentes' },
      { title: 'Troca e Devoluções' },
      { title: 'Entrega' },
    ],
    contact: null,
    mainCategories: null,
    segments: null,
    specialDates: null,
  }),
  methods: {
    getWidth() {
      // ? '30%' : '80%'
      if (this.$vuetify.breakpoint.xl) {
        return '20%'
      } else if (this.$vuetify.breakpoint.lg) {
        return '20%'
      } else {
        return '30%'
      }
    },
    getContact() {
      this.$api
        .get(`contact/get/1`)
        .then((res) => {
          this.contact = res.data.data
          this.items[0].text = this.contact.wpp
          this.items[1].text = this.contact.phone_number
          this.items[2].text = this.contact.email
          let address = ''

          address += this.contact.address && `${this.contact.address}, `
          address += this.contact.number && `${this.contact.number}, `
          address += this.contact.district && `${this.contact.district}, `
          address += this.contact.cep && `CEP: ${this.contact.cep}`
          this.items[3].text = address
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getPrints() {
      this.$api
        .get(`prints/list`)
        .then((res) => {
          this.prints = res.data.data
          // this.prints.map(item =>{
          //     item.route = '/print/'+item.route
          // })
        })
        .catch((err) => {
          console.error(err)
        })
    },
    goTo(print) {
      this.$router.push(`/print/${print.route}`)
      // this.$router.push(`${print.route}`)
    },
    getMainCategories() {
      this.$api
        .get('categories_site/mains')
        .then((res) => {
          this.mainCategories = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getSegmentsCategories() {
      this.$api
        .get('categories_site/segments')
        .then((res) => {
          this.segments = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getSpecialDatesCategories() {
      this.$api
        .get('categories_site/special_dates')
        .then((res) => {
          this.specialDates = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    setProductsCategorySearch(type, item) {
      this.$store.commit('setProductsSearch', {
        type: type,
        category_name: item,
      })
      this.$router.push('/products')
    },
  },
  beforeMount() {
    this.getContact()
  },
}
</script>
